.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;

        &.animate {
            opacity: 1;
            transform: translateY(0);
        }

        .project-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.2) 0,
                    rgba(0, 0, 0, 1));
            bottom: -70px;
            box-sizing: border-box;
            overflow: hidden;
            backdrop-filter: blur(40px);
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}


@media screen and (max-width: 1200px) {
    .portfolio-page {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .portfolio-page .images-container {
        gap: 10px;
        padding-bottom: 50px;
        justify-content: center;
    }

    .portfolio-page .image-box {
        flex: 1 1 45%;
        max-width: calc(50% - 10px);
        height: 300px;
    }

    .portfolio-page .image-box .title {
        font-size: 20px;
    }

    .portfolio-page .image-box .description {
        font-size: 12px;
    }

    .portfolio-page .image-box .btn {
        height: 35px;
        line-height: 30px;
        font-size: 12px;
        padding: 0 15px;
    }
}