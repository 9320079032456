body {
    margin: 0;
    overflow: hidden;
}

.container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.shape {
    position: absolute;
    top: -20px;
    font-size: 20px;
    animation: fall 3s linear infinite;
    animation-fill-mode: backwards;
}

@keyframes fall {
    0% {
        transform: translateY(0);
        opacity: .7;
    }

    90% {
        transform: translateY(100vh);
        opacity: 0.6;
    }

    100% {
        transform: translateY(100vh);
        opacity: 0;
    }
}


@media screen and (max-width: 1200px) {
    .shape {
        animation: fallReduced 3s linear infinite;
        animation-fill-mode: backwards;
    }

    @keyframes fallReduced {
        0% {
            transform: translateY(0);
            opacity: 0.2;
        }

        90% {
            transform: translateY(100vh);
            opacity: 0.2;
        }

        100% {
            transform: translateY(100vh);
            opacity: 0;
        }
    }
}